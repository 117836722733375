<template>
  <HeaderBar v-if="headerFooterType === 'trip'"></HeaderBar>
  <HeaderBarBusiness v-else-if="headerFooterType === 'business'"></HeaderBarBusiness>
  <div id="container">
    <router-view :key="route.fullPath" />
  </div>
  <FooterBar v-if="headerFooterType === 'trip'"></FooterBar>
  <FooterBarBusiness v-else-if="headerFooterType === 'business'"></FooterBarBusiness>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { computed, defineAsyncComponent, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const { locale } = useI18n()
const route = useRoute()

// 1. 為了讓雲收銀元件, 不吃<Footer>與<Header>
const headerFooterType = computed(() => {
  if (route.path.endsWith("/cashier")) {
    return 'cashier'
  }
  else if (route.path.startsWith("/trip")) {
    return 'trip'
  }
  else {
    return 'business'
  }
})

const HeaderBar = defineAsyncComponent(() => import('@/components/HeaderBar.vue'))
const FooterBar = defineAsyncComponent(() => import('@/components/FooterBar.vue'))
const HeaderBarBusiness = defineAsyncComponent(() => import('@/components/HeaderBar_Business.vue'))
const FooterBarBusiness = defineAsyncComponent(() => import('@/components/FooterBar_Business.vue'))

onMounted(() => {
  document.documentElement.lang = locale.value;
})
watch(locale, (newLocale) => {
  document.documentElement.lang = newLocale
})
</script>
<style lang="scss">
@import "~bootstrap/scss/bootstrap";
@import './assets/sass/all';
</style>
