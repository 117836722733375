import { defineStore } from "pinia";
import { ref } from "vue";

export const useFavoriteStore = defineStore("favorite", ()=> {
  const loveList = ref([]);

  // 1. 加入 or 刪除 我的最愛
  const addMyLove = (hotelCode) => {
    // 1.1 查看是否以加入我的最愛, 是 >> 就會有 loveExisted
    const loveExisted = loveList.value.find((item) => item === hotelCode);
    // 1.1 若已是我的最愛, 就砍掉其飯店
    if(loveExisted) {
      loveList.value = loveList.value.filter((item, index) => {
        return item !== loveExisted
      })
    }
    // 1.2 加入我的最愛 
    else {
      loveList.value.unshift(hotelCode)
    }
  }


  return { loveList, addMyLove }
},

  { 
    persist: { key: "myFavorite", paths:["loveList"] } 
  }
)