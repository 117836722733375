import { createRouter, createWebHistory } from 'vue-router'
import { useSiteStore } from '@/stores/siteStore';
import { useAuthStore } from '@/stores/authStore';
import { useHotelStore } from '@/stores/hotelStore';
import { AuthService } from '@/service/AuthService';
// Cookie
import { CookieService } from '@/service/CookieService.js';
import jwt from 'jsonwebtoken';
import axios from 'axios';

const routes = [
  {
    path: '/',
    component: () => import('@/views/BusinessHome.vue'),
  },
  {
    path: '/home',
    component: () => import('@/views/BusinessHome.vue'),
    name: 'Home'
  },
  {
    path: '/company',
    component: () => import('@/views/BusinessCompany.vue'),
    name: 'Company'
  },
  {
    path: '/insight',
    component: () => import('@/views/BusinessInsight.vue'),
    name: 'Insight'
  },
  {
    path: '/line',
    component: () => import('@/views/BusinessLine.vue'),
    name: 'Line'
  },
  {
    path: '/trip',
    name: 'trip',
    redirect: () => {
      const siteStore = useSiteStore();
      return { name: 'index_locale', params: { locale: siteStore.settings.displayLanguages } };
    }
  },
  {
    path: '/trip/:locale',
    sensitive: true,
    component: () => import('@/views/IndexView.vue'),
    name: 'index_locale',
    prop: true,
  },
  {
    path: '/trip/:locale/Ikenotaira/', // 池之平
    component: () => import('@/views/Project-Ikenotaira.vue'),
    name: 'Ikenotaira'
  },
  {
    path: '/trip/:locale/Zagyosoh/', // 坐漁莊
    component: () => import('@/views/Project-Zagyosoh.vue'),
    name: 'Zagyosoh'
  },
  {
    path: '/trip/:locale/Daytours', // 一日遊
    component: () => import('@/views/Travel-daytours.vue'),
    name: 'Daytours',
  },
  {
    path: '/trip/:locale/Karuizawa', // 輕井澤一日遊
    component: () => import('@/views/Travel-Karuizawa.vue'),
    name: 'Karuizawa',
  },
  {
    path: '/trip/:locale/Symphony', // Hotel symphony
    component: () => import('@/views/Travel-Symphony.vue'),
    name: 'Symphony',
  },
  {
    // path: '/uc',
    path: "/:locale/:pathMatch(.*)*", // 任何不存在的path, 則進入 UnderConstruction.vue
    component: () => import('@/views/UnderConstruction.vue')
  },
  {
    path: '/trip/:locale/privacypolicy',
    component: () => import('@/views/PrivacyPolicy.vue'),
    name: 'PrivacyPolicy'
  },
  {
    path: "/trip/:locale/hotelList",  // keywords搜尋後之飯店清單
    component: () => import("@/views/HotelLists.vue"),
    name: 'HotelLists',
  },
  {
    path: "/trip/:locale/hotelDetail",  // 指定飯店之詳細方案&房型
    component: () => import("@/views/HotelDetail.vue"),
    name: 'HotelDetail',
  },
  {
    path: "/trip/:locale/hotelCheck", // 客人填寫表單頁
    component: () => import("@/views/HotelCheck.vue"),
    name: "hotelCheck",
    meta: { requiresAuth: true }
  },
  {
    path: "/trip/:locale/myAccount", // 我的帳號
    component: () => import("@/components/MyMember/MyAccount.vue"),
    name: "myAccount",
    meta: { requiresAuth: true }
  },
  {
    path: "/trip/:locale/myOrder", // 我的訂單
    component: () => import("@/components/MyMember/MyOrder.vue"),
    name: "myOrder",
    meta: { requiresAuth: true, keepAlive: true },
  },
  {
    path: "/trip/:locale/myReferral", // 我的推薦人
    component: () => import("@/components/MyMember/MyReferral.vue"),
    name: "myReferral",
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const siteStore = useSiteStore();
      if (siteStore.userInfo.IdentityType === '1') {
        next();
      } else {
        // 回上一頁
        router.go(-1);
      }
    }
  },
  {
    path: "/trip/:locale/myFavorite", // 我的最愛
    component: () => import("@/components/MyMember/MyFavorite.vue"),
    name: "myFavorite",
    meta: { requiresAuth: true }
  },
  {
    path: "/trip/:locale/orderCompleted", // 完成訂單(4 Steps)
    component: () => import("@/components/Order/OrderCompleted.vue"),
    name: "OrderCompleted",
  },
  {
    path: "/trip/:locale/orderFailed", // 訂單失敗
    component: () => import("@/components/Order/OrderFailed.vue"),
    name: "OrderFailed",
  },
  {
    path: "/trip/:locale/Order", //「指定」飯店訂單
    component: () => import("@/components/Order/Order.vue"),
    name: "Order",
  },
  {
    path: "/trip/:locale/cashier", // 雲收銀Loading
    component: () => import("@/components/PlanForm/CashierFormData.vue"),
    name: "Cashier"
  },
  {
    path: '/Auth', // 第三方登入後api回傳的頁面
    name: 'Auth',
    beforeEnter: (to, from, next) => {
      const siteStore = useSiteStore();
      try {
        if (to.query["Status"] == "Failure") {
          next({ path: '/trip' });
        }
        let token = to.query["Token"];
        let refreshToken = to.query["RefreshToken"];
        localStorage.setItem('AccessToken', token);
        localStorage.setItem('RefreshToken', refreshToken);
        let jwtDecode = '';
        if (token !== null || token !== "undefined") {
          jwtDecode = jwt.decode(token);
          if (jwtDecode) {
            siteStore.userInfo = jwtDecode;
          }
        }
        const lastPage = localStorage.getItem("LastPage") ?? '';

        // 發送設備碼及推薦人的推薦碼
        const apiUrl = process.env.VUE_APP_API_URL + '/api/V1/P02/A06/A02/P02A06A02M005';
        let referralCode = CookieService.getItem("ReferralCode") ?? '';
        let equipmentNo = localStorage.getItem("ENO") ?? '';
        const data = {
          "Language": siteStore.settings.displayLanguages,
          "Nbr": jwtDecode.Nbr ?? '',
          "Email": jwtDecode.email ?? '',
          "SrcAttr": jwtDecode.Source ?? '',
          "RecommendedBy": referralCode ?? '',
          "EquipmentNo": equipmentNo
        }
        axios.post(apiUrl, data)
          .then(response => {
            //console.log('登入成功發送');
          })
          .catch(error => {
            console.error('發送登入時出錯', error);
            next({ path: '/trip' });
          });
        if (lastPage) {
          next(lastPage);
        } else {
          next({ path: '/trip' });
        }
      } catch (error) {
        next({ path: '/trip' });
      }
      finally {
        localStorage.removeItem("LastPage");
      }
    }
  },
  {
    path: '/trip/:locale/flightOrder',
    name: 'flightOrder', //「指定」機票訂單
    beforeEnter: (to, from, next) => {
      const siteStore = useSiteStore();
      AuthService.getTicket(siteStore.userInfo.email).then(response => {
        window.location.href = `${process.env.VUE_APP_Flight_URL}/${switchLang(siteStore.settings.displayLanguages)}/orderList?ticket=${response.Data}`;
      }).catch(error => {
        console.error('error', error);
      });
    }
  },
  {
    path: '/trip/:locale/flight',
    name: 'flight', //「指定」機票館
    beforeEnter: (to, from, next) => {
      const siteStore = useSiteStore();
      AuthService.getTicket(siteStore.userInfo.email).then(response => {
        if (siteStore.userInfo?.email) {
          window.location.href = `${process.env.VUE_APP_Flight_URL}/${switchLang(siteStore.settings.displayLanguages)}?ticket=${response.Data}`;
        }
        else {
          window.location.href = `${process.env.VUE_APP_Flight_URL}/${switchLang(siteStore.settings.displayLanguages)}`;
        }
      }).catch(error => {
        console.error('error', error);
      });
    }
  },
]

function switchLang(lang) {
  switch (lang) {
    case 'zh-TW':
      return 'Tw';
    case 'zh-CN':
      return 'Cn';
    case 'ja-JP':
      return 'Ja';
    case 'en-US':
      return 'En';
    default:
      return 'Ja';
  }
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: function (to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    } else {
      console.log("moving to top of the page");
      window.scrollTo(0, 0);
    }
  },
  strict: true,
})

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  const siteStore = useSiteStore();
  // 檢查並更新語言設置
  if (to.params.locale) {
    siteStore.settings.displayLanguages = to.params.locale;
  }
  //#region 記錄進入網頁設備的資訊(開始)
  siteStore.recordEquipmemt(siteStore, from, to, next);
  // #endregion
  // #region 取得登入狀態(開始)
  AuthService.checkToken(authStore, siteStore, to, from, next);
  // #endregion
  if (to.query?.ticket) {
    var isAuth = await AuthService.checkTicket(siteStore, to.query?.ticket);
    if (isAuth) {
      next({ path: to.path, query: {} });
    }
  }
  else if (to.meta.requiresAuth && !siteStore.userInfo) {
    next({ path: '/trip' });
  }
  else {
    next();
  }
});


// 1. 若不是進入「飯店列表」以及「房型方案」, 進階搜尋變數全初始化!
router.afterEach((to, from) => {
  const hotelStore = useHotelStore();
  if (to.name !== 'HotelLists' && to.name !== 'HotelDetail') {
    hotelStore.advancedParams.smoking = null;
    hotelStore.advancedParams.bedType = null;
    hotelStore.advancedParams.facilityArr = [];
    hotelStore.filteredAllHotels.HotelPriceRange = {}
  }
})

export default router