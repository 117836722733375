import axios from "axios";
import jwt from 'jsonwebtoken';

export class AuthService {
  static apiKey = "";
  static apiSecret = "";

  static async getAccessToken() {
    return process.env.VUE_APP_ACCESSTOKEN;
  }

  static loginGoogle() {
    const googleAuthUrl = `${process.env.VUE_APP_API_URL}/api/Oauth/LoginGoogle`
    if (navigator.userAgent) {
      const customUserAgent = 'Mozilla/5.0 (Linux; Android 13; SM-G986U1 Build/TP1A.220624.014; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/111.0.5563.116 Mobile Safari/537.36 WEBVIEW TEST/1.3.1.106 (Phone; anonymous)';
      window.open(googleAuthUrl, '_self', `user-agent=${customUserAgent}`);
    } else {
      window.location.href = googleAuthUrl;
    }
  }

  static loginFB() {
    const FBAuthUrl = `https://www.facebook.com/v19.0/dialog/oauth?
client_id=${process.env.VUE_APP_FB_CLIENT_ID}
&scope=openid,public_profile,email
&display=popup
&response_type=code
&redirect_uri=${process.env.VUE_APP_FB_REDIRECT_URI}
&state=${process.env.VUE_APP_ACCESSTOKEN}`

    window.location.href = FBAuthUrl;
  }

  static logOut = () => {
    window.location.href = process.env.VUE_APP_API_URL + "/api/Oauth/Logout";
  };

  static refreshToken = async (refreshToken) => {
    const encodedRefreshToken = encodeURIComponent(refreshToken);
    const apiUrl = `${process.env.VUE_APP_API_URL}/api/Oauth/AccessToken?RefreshToken=${encodedRefreshToken}`;
    try {
      const response = await axios.get(apiUrl);
      return response.data;
    } catch (error) {
      console.error('Error refreshing token:', error);
    }
  };

  static checkToken = (authStore, siteStore, from, to, next) => {
    let token = localStorage.getItem("AccessToken");
    let refreshToken = localStorage.getItem("RefreshToken");
    // 確認是否有登入
    siteStore.userInfo = "";
    if (token?.length > 0 && token !== "undefined" && token !== "null") {
      let jwtDecode = jwt.decode(token);
      // 確認token是否過期，過期則重新則確認真否有refreshToken，有則重新取得AccessToken
      if (jwtDecode.exp < Date.now() / 1000) {
        localStorage.removeItem("AccessToken");
        // 有refreshToken，重新取得AccessToken
        if (refreshToken && refreshToken.length > 0) {
          authStore.refreshToken(refreshToken).then(accessToken => {
            // refreshToken失效, 則刪除LocalStorage，提示重新登入
            if (accessToken == null || accessToken == "undefined") {
              localStorage.removeItem("AccessToken");
              localStorage.removeItem("RefreshToken");
              if (to.meta.requiresAuth) {
                authStore.errorSwal('Error', 'Refreshing token invalid');
                next({ path: '/trip' });
              }
              return;
            }
            // refreshToken有效，重新設定AccessToken
            localStorage.setItem("AccessToken", accessToken);
            let jwtDecode = jwt.decode(accessToken);
            siteStore.userInfo = jwtDecode;
          }).catch(error => {
            console.error("Error refreshing token:", error);
            if (to.meta.requiresAuth) {
              authStore.errorSwal('Error', 'Refreshing token invalid');
              next({ path: '/trip' });
            }
            return;
          });
        }
      }
      else {
        // token未過期，直接設定userInfo
        siteStore.userInfo = jwtDecode;
      }
    }
    // 如果token不存在，且有refreshToken，重新取得AccessToken
    else if ((token == null || token == "undefined" || token == "null") && refreshToken && refreshToken.length > 0) {
      authStore.refreshToken(refreshToken).then(accessToken => {
        // refreshToken失效, 則刪除LocalStorage，提示重新登入
        if (accessToken == null || accessToken == "undefined") {
          localStorage.removeItem("AccessToken");
          localStorage.removeItem("RefreshToken");
          if (to.meta.requiresAuth) {
            authStore.errorSwal('Error', 'Refreshing token invalid');
            next({ path: '/trip' });
          }
          return;
        }
        // refreshToken有效，重新設定AccessToken
        localStorage.setItem("AccessToken", accessToken);
        let jwtDecode = jwt.decode(accessToken);
        siteStore.userInfo = jwtDecode;
      }).catch(error => {
        localStorage.removeItem("AccessToken");
        localStorage.removeItem("RefreshToken");
        console.error("Error Refreshing token:", error);
        if (to.meta.requiresAuth) {
          authStore.errorSwal('Error', 'Refreshing token invalid');
          next({ path: '/trip' });
        }
        return;
      });
    }
  }

  static checkTicket = async (siteStore, ticket) => {
    let token = localStorage.getItem("AccessToken");
    let refreshToken = localStorage.getItem("RefreshToken");
    if ((token === null || token === "undefined" || token === 'null') && (refreshToken === null || refreshToken === 'null' || refreshToken === "undefined")) {
      const apiUrl = process.env.VUE_APP_API_URL + '/api/User/GetLoginToken?ticket=' + ticket;
      const response = await axios.get(apiUrl, {
        headers: {
          'Authorization': process.env.VUE_APP_ACCESSTOKEN
        }
      })
      if (response.status === 200) {
        const data = response.data;
        const params = new URLSearchParams(data);
        const token = params.get('Token');
        const refreshToken = params.get('RefreshToken');
        localStorage.setItem('AccessToken', token);
        localStorage.setItem('RefreshToken', refreshToken);
        let jwtDecode = '';
        if (token !== null || token !== "undefined") {
          jwtDecode = jwt.decode(token);
          if (jwtDecode) {
            siteStore.userInfo = jwtDecode;
            return true;
          }
        }
      }
    }
    return false;
  }

  static getTicket = async (email) => {
    const apiUrl = `${process.env.VUE_APP_API_URL}/api/User/GetTicket`;
    const data = {
      Email: email
    }
    try {
      const response = await axios.post(apiUrl, data, {
        headers: {
          'Authorization': process.env.VUE_APP_ACCESSTOKEN
        }
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error('Error refreshing token:', error);
    }
  }
}
