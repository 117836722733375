import { defineStore } from 'pinia'
import { ref, computed, reactive } from 'vue'
import { SiteService } from "@/service/SiteService";
import {CookieService} from '@/service/CookieService.js';
import axios from 'axios';

export const useSiteStore = defineStore('site', () => {
    const settings = reactive({
        languages: null,
        displayLanguages: SiteService.getUserDisplayLanguage(),
        tagList: SiteService.getTagList(),
        videoList: null,
        isCalendar: false,
    });

    const userInfo = ref(null);

    const searchCategories = ref("01");

    const newsList = computed(() => {
        return SiteService.getNews();
    })

    const themeList = computed(() => {
        return SiteService.getThemeList(settings.displayLanguages)
    })

    const bannerList = computed(() => {
        return SiteService.getBannerList(settings.displayLanguages);
    })

    // 首頁Banner - 手機板
    const bannerList_Mobile = computed(() => {
        return SiteService.getBannerList_Mobile(settings.displayLanguages);
    })

    const topDestinations = computed(() => {
        return SiteService.getTopDestinations(settings.displayLanguages);
    })

    const popularData = computed(() => {
        return SiteService.getPopularDestination(settings.displayLanguages);
    })

    const mapData = computed(() => {
        return SiteService.getMap(settings.displayLanguages);
    })


    // get video list at intersection observer
    function getVideoList() {
        this.settings.videoList = SiteService.getVideo().slice(0, 18);
    }

    // update supported languages list
    function updateLanguages() {
        try {
            let langListData = SiteService.getSupportedLanguages();
            return this.settings.languages = langListData;
        } catch (error) {
            console.log(error);
        }
    }
    // update display languages and set cookie
    function updateDisplayLanguages(langCode) {
        SiteService.updateUserDisplayLanguage(langCode);
        this.settings.displayLanguages = langCode;
    }

    // 坐漁莊四國語言圖片 - 桌機
    const zagyosohList = computed(() => {
        return SiteService.getZagyosohList(settings.displayLanguages)
    })

    // 坐漁莊四國語言圖片 - 手機
    const zagyosohList_Mobile = computed(() => {
        return SiteService.getZagyosohList_Mobile(settings.displayLanguages)
    })


    // 池之平四國語言圖片
    const ikenotairaList = computed(() => {
        return SiteService.getIkenotairaList(settings.displayLanguages)
    })

    // 池之平四國語言圖片
    const ikenotairaList_Mobile = computed(() => {
        return SiteService.getIkenotairaList_Mobile(settings.displayLanguages)
    })

    // 一日遊四國語言圖片
    const daytours = computed(() => {
        return SiteService.getDaytours(settings.displayLanguages)
    })

    function recordEquipmemt(siteStore, from, to, next) {
        const apiUrl = process.env.VUE_APP_API_URL + '/api/V1/P02/A06/A02/P02A06A02M004';
        let referralCode = CookieService.getItem("ReferralCode");
        let equipmentNo = localStorage.getItem("ENO") ?? '';
        if (to.query.Code && (referralCode == "" || referralCode == null || referralCode == undefined)) {
            referralCode = to.query.Code;
            const expirationDate = new Date();
            expirationDate.setDate(expirationDate.getDate() + 1);
            CookieService.setItem("ReferralCode", referralCode, expirationDate, '/');
        }
        if (equipmentNo == "" || equipmentNo == null || equipmentNo == undefined) {
            equipmentNo = SiteService.createEquipmentInfo();
            localStorage.setItem("ENO", equipmentNo);
        }
        const data = {
            userId: siteStore.userInfo?.Nbr ?? '',
            equipmentNo: equipmentNo ?? '',
            entryUrl: to.fullPath,
            referralCode: referralCode ?? '',
        };
        axios.post(apiUrl, data)
            .then(response => {
                //console.log('頁面訪問記錄成功發送');
                return;
            })
            .catch(error => {
                console.error(error);
                console.error(from);
            });
    }

    return { recordEquipmemt, userInfo, settings, topDestinations, bannerList, themeList, popularData, searchCategories, mapData, updateLanguages, updateDisplayLanguages, getVideoList, newsList, zagyosohList, ikenotairaList, zagyosohList_Mobile, ikenotairaList_Mobile, bannerList_Mobile, daytours }
},
    {
        persist: [
            {
                storage: localStorage,
                key: "StorageUserInfo",
                paths: ["userInfo"]
            },
            {
                // 語系, 用sessionStorage, 網頁若關閉, 下次進入只抓瀏覽器預設語言
                storage: sessionStorage,
                key: "displayLang",
                paths: ["settings.displayLanguages"]
            }
        ]
    })