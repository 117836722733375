import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js'

import 'bootstrap/dist/js/bootstrap.bundle.min.js'

// Axios
import axios from 'axios'
import VueAxios from 'vue-axios'

// Vue Inline SVG
import InlineSvg from 'vue-inline-svg';

// Fort Awesome
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

//I18N
import enUS from './translations/en-US.json';
import zhTW from './translations/zh-TW.json';
import zhCN from './translations/zh-CN.json';
import jaJP from './translations/ja-JP.json';
import { createI18n } from 'vue-i18n'

//Pinia
import { createPinia } from 'pinia'
const pinia = createPinia()

// SweetAlert2
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const app = createApp(App)

const i18n = createI18n({
  legacy: false,
  locale: "ja-JP",
  messages: {
    "en-US": enUS,
    "zh-TW": zhTW,
    "zh-CN": zhCN,
    "ja-JP": jaJP,
  },
  fallbackLocale: 'en-US',
});


// Vue Inline SVG
app.component('inline-svg', InlineSvg);

// 4. ======== 千分號方法 ========
import { currency, date } from '@/methods/filters.js'
app.config.globalProperties.$filters = { currency, date }

// 5. Persist
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
pinia.use(piniaPluginPersistedstate);

// 6. Vee-Validate
import {Field, Form, ErrorMessage, defineRule} from 'vee-validate';
// 引入 VeeValidate 的驗證規則, typeof這行, 是Bug解決之判斷
import * as AllRules from '@vee-validate/rules';
Object.keys(AllRules).forEach((rule) => {
  if(typeof AllRules[rule] === 'function'){
    defineRule(rule, AllRules[rule]);
  }
});

app.component('VField', Field);
app.component('VForm', Form);
app.component('ErrorMessage', ErrorMessage);

app.use(VueAxios, axios)
app.use(i18n)
app.use(pinia)

app.use(VueSweetalert2)
app.use(router)
app.mount('#app')


